import React, { FormEvent, ChangeEvent } from "react"
import TextField from "@material-ui/core/TextField"
import Icon from "./Icon"
import { CheckoutFormData } from "../types"
import { graphql, useStaticQuery } from "gatsby"
import { getSubtotal } from "../utils"

interface FormCoupon {
  [key: string]: { type: "Percentage" | "Dollar Amount"; amount: number }
}
const getCouponDiscount = (
  coupon: {
    type: "Percentage" | "Dollar Amount"
    amount: number
    code: string
  },
  subtotal: number
) => {
  if (coupon.type === "Percentage") {
    return Math.ceil((subtotal * coupon.amount) / 100)
  } else if (coupon.type === "Dollar Amount") {
    return coupon.amount
  }
}

const CheckoutCouponForm: React.FC<{
  formData: CheckoutFormData
  setFormData: React.Dispatch<React.SetStateAction<CheckoutFormData>>
}> = ({ formData, setFormData }) => {
  const couponData = useStaticQuery(graphql`
    query {
      allCoupon(filter: { enabled: { eq: true } }) {
        edges {
          node {
            code
            amount
            type
          }
        }
      }
    }
  `)
  const validCouponCodes = couponData.allCoupon.edges.reduce(
    (
      a: FormCoupon,
      {
        node,
      }: {
        node: {
          code: string
          amount: number
          type: "Dollar Amount" | "Percentage"
        }
      }
    ) => {
      a[node.code] = {
        amount: node.amount,
        type: node.type,
      }
      return a
    },
    {}
  )
  const [open, setOpen] = React.useState(false)
  const [couponCode, setCouponCode] = React.useState("")
  const [couponError, setCouponError] = React.useState("")
  const toggleOpen = () => {
    setOpen(val => !val)
  }
  const handleCancel = () => {
    const answer = window.confirm(
      "Are you sure you want to remove this promo code?"
    )
    if (answer) {
      setCouponCode("")
      setFormData(data => {
        return {
          ...data,
          clientUpdatedCartItems: true,
          coupon: null,
        }
      })
    }
  }
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    const formattedCouponCode = couponCode.trim().toUpperCase()
    e.preventDefault()
    if (couponCode && validCouponCodes.hasOwnProperty(formattedCouponCode)) {
      setCouponError("")
      setOpen(false)
      setFormData(data => {
        return {
          ...data,
          clientUpdatedCartItems: true,
          coupon: {
            code: formattedCouponCode,
            discount: getCouponDiscount(
              {
                type: validCouponCodes[formattedCouponCode].type,
                amount: validCouponCodes[formattedCouponCode].amount,
                code: formattedCouponCode,
              },
              getSubtotal(formData.cartItems)
            ),
            type: validCouponCodes[formattedCouponCode].type,
          },
        }
      })
    } else {
      setCouponError("This promo code is not valid")
    }
  }
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCouponCode(e.target.value)
  }
  if (formData.coupon) {
    return (
      <div className="promo-code-container applied">
        <div>
          Promo Code <strong>{formData.coupon.code.toUpperCase()}</strong>{" "}
          Applied
        </div>
        <div>
          <button className="flex-justify-center btn" onClick={handleCancel}>
            <Icon iconName="close" width={24} />
          </button>
        </div>
      </div>
    )
  }
  return (
    <div className="promo-code-container">
      <button
        className={`promo-code-btn${open ? " active" : ""}`}
        onClick={toggleOpen}
      >
        <div>Apply Promo Code</div>
        <div className="icon">
          <Icon iconName="chevronDown" width={20} />
        </div>
      </button>
      <div className="promo-form-container">
        {open && (
          <form action="#" onSubmit={handleSubmit}>
            <div className="promo-form-grid">
              <div className="text-field">
                <TextField
                  name="coupon"
                  label="Promo Code"
                  onChange={handleChange}
                  error={Boolean(couponError)}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div className="btn-container">
                <button className="btn primary-btn rounded-btn" type="submit">
                  APPLY
                </button>
              </div>
            </div>
            {couponError && (
              <div className="error-msg coupon-error-msg">{couponError}</div>
            )}
          </form>
        )}
      </div>
    </div>
  )
}

export default CheckoutCouponForm
