import React from "react"
import Layout from "./Layout"
import Logo from "./Logo"
import CheckoutForm from "./CheckoutForm"

export default ({ location }) => {
  const [invoiceId, setInvoiceId] = React.useState<string | null>(null)
  React.useEffect(() => {
    window.onbeforeunload = () => "You have unsaved changes"
    if (location.search) {
      const id = location.search.split("=")[1]
      if (id) {
        setInvoiceId(id)
      }
    }
  }, [])
  return (
    <Layout
      pageTitle="Secure Checkout"
      hideNavbar
      hideFooter
      pathname="/checkout/"
    >
      <div>
        <header className="layout-container border-bottom">
          <div className="checkout-header">
            <div className="logo">
              <a className="logo-container" href="/" title="Dzign Studio">
                <Logo />
              </a>
            </div>
            <div className="flex-justify-center checkout-title">
              <h5>CHECKOUT</h5>
            </div>
          </div>
        </header>
        <div>
          <CheckoutForm invoiceId={invoiceId} />
          {/* <div style={{ padding: 12, textAlign: "center" }}>
            We are currently taking a break so you cannot check out at this
            time.
          </div> */}
        </div>
      </div>
    </Layout>
  )
}
