import React from "react"
import CardDisplay from "./CardDisplay"
import { CheckoutFormData } from "../types"

const FinishedStepBilling: React.FC<{ formData: CheckoutFormData }> = ({
  formData,
}) => {
  const { id, ...cardProps } = formData.paymentMethod
  return (
    <>
      <div className="finished-step">
        <CardDisplay {...cardProps} />
      </div>
    </>
  )
}

export default FinishedStepBilling
