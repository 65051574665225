import React from "react"
import Icon from "./Icon"

interface CardDisplayProps {
  brand: string
  last4: string
  expMonth: number
  expYear: number
  handleRemoveCard?: () => void
}
const validCardBrands = ["visa", "mastercard", "amex", "discover"]
const CardDisplay: React.FC<CardDisplayProps> = ({
  brand,
  expMonth,
  expYear,
  last4,
  handleRemoveCard,
}) => {
  return (
    <div className="checkout-small flex-center">
      <div className="text-muted">
        <Icon
          iconName={validCardBrands.includes(brand) ? brand : "creditCard"}
          width={40}
        />
      </div>
      <div className="padding-1">
        <p>
          {brand.toUpperCase()} ...{last4}
        </p>
        <p>
          Exp: {expMonth}/{expYear}
        </p>
      </div>
      {handleRemoveCard && (
        <div className="padding-1">
          <button className="btn checkout-edit-btn" onClick={handleRemoveCard}>
            Remove
          </button>
        </div>
      )}
    </div>
  )
}

export default CardDisplay
