import React from "react"
import { Field } from "formik"
import TextField from "@material-ui/core/TextField"

export interface CheckoutFieldProps {
  label: string
  grid: 1 | 2
  name: string
  type?: string
  options?: string[]
  helperText?: string
}
const CheckoutField: React.FC<CheckoutFieldProps> = ({
  label,
  grid,
  name,
  type = "text",
  options,
  helperText = "",
}) => {
  if (options) {
    return (
      <div className={`grid-item-${grid} padding-1`}>
        <Field name={name}>
          {({ field, meta }) => {
            const hasError = Boolean(meta.touched && meta.error)
            return (
              <TextField
                {...field}
                fullWidth
                variant="outlined"
                label={label}
                select
                SelectProps={{ native: true }}
                error={hasError}
                helperText={hasError ? meta.error : helperText}
              >
                {options.map(option => {
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  )
                })}
              </TextField>
            )
          }}
        </Field>
      </div>
    )
  }
  return (
    <div className={`grid-item-${grid} padding-1`}>
      <Field name={name}>
        {({ field, meta }) => {
          const hasError = Boolean(meta.touched && meta.error)
          return (
            <TextField
              {...field}
              type={type}
              fullWidth
              variant="outlined"
              label={label}
              error={hasError}
              helperText={hasError ? meta.error : helperText}
            />
          )
        }}
      </Field>
    </div>
  )
}

export default CheckoutField
