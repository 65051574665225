import React from "react"
import { Link } from "gatsby"
import Layout from "./Layout"
import Icon from "./Icon"
import { CONTACT_US_ROUTE } from "../constants"

export default () => {
  return (
    <Layout pageTitle="Payment Received" pathname="/checkout/payment-success/">
      <div className="flex-justify-center small-page-container">
        <div className="flex-justify-center success-icon">
          <Icon width={100} iconName="packageCheck" />
        </div>
        <h4 className="margin-top-2">Success!</h4>
        <p>
          We have successfully received your payment. You will shortly receive
          an email confirmation for your purchase. If you have any questions,
          please feel free to{" "}
          <Link to={CONTACT_US_ROUTE} className="default">
            contact us
          </Link>
          .
        </p>
        <div className="margin-top-2">
          <Link
            to="/"
            className="btn primary-btn rounded-btn full-width-mobile"
          >
            BACK TO HOME PAGE
          </Link>
        </div>
      </div>
    </Layout>
  )
}
