import React from "react"
import { navigate } from "gatsby"
import CartItemContainer from "./CartItemContainer"
import { FormComponentProps } from "./CheckoutForm"
import {
  CartItem,
  CheckoutFormData,
  Coupon,
  ServerCheckoutItems,
} from "../types"
import CheckoutSummaryTable from "./CheckoutSummaryTable"
import useFirebase from "../useFirebase"
import { useStripe } from "@stripe/react-stripe-js"
import { CART } from "../constants"
import {
  convertCentsToCurrency,
  removeLocalStorage,
  setLocalStorage,
} from "../utils"
import FullPageLoading from "./FullPageLoading"

interface StagedOrderInput {
  invoiceId?: string
  shipping: CheckoutFormData["shipping"]
  billing: CheckoutFormData["billing"]
  cartItems: {
    name: string
    price: number
    productId: string
    quantity: number
    priceOption: string
    styleOption: string
    personalization: string
    vinylColor?: string
    stainColor?: string
    imagePath?: string
  }[]
  coupon: Coupon
  deliveryMethod: "delivery" | "pickup"
}
const CheckoutFormReview: React.FC<FormComponentProps> = ({
  formData,
  setFormData,
}) => {
  const [updating, setUpdating] = React.useState(false)
  const [formError, setFormError] = React.useState("")
  const [submitting, setSubmitting] = React.useState(false)
  const firebase = useFirebase()
  const stripe = useStripe()
  function updateCart(updatedCartItems: CartItem[]) {
    setLocalStorage(CART, updatedCartItems)
    setFormData({
      ...formData,
      cartItems: updatedCartItems,
      clientUpdatedCartItems: true,
    })
  }
  function updateCartItem(cartItem: CartItem, index: number) {
    const updatedCartItems = [...formData.cartItems]
    updatedCartItems[index] = cartItem
    updateCart(updatedCartItems)
  }
  function removeCartItem(index: number) {
    const updatedCartItems = [...formData.cartItems]
    updatedCartItems.splice(index, 1)
    updateCart(updatedCartItems)
  }
  function processServerCheckoutItems(
    serverCheckoutItems: ServerCheckoutItems
  ) {
    const newItems = []
    for (const item of formData.cartItems) {
      if (serverCheckoutItems[item.productId]) {
        newItems.push({
          ...item,
          price: serverCheckoutItems[item.productId].price,
        })
      }
    }
    return newItems
  }
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (!stripe || !formData.stagedOrder) {
      return
    }
    setSubmitting(true)
    const result = await stripe.confirmCardPayment(
      formData.stagedOrder.clientSecret,
      {
        payment_method: formData.paymentMethod.id,
        shipping: {
          address: {
            line1: formData.shipping.addressLine1,
            line2: formData.shipping.addressLine2,
            city: formData.shipping.city,
            country: "US",
            postal_code: formData.shipping.zip,
            state: formData.shipping.state,
          },
          name: formData.shipping.name,
        },
      }
    )
    if (result.error) {
      setFormError(result.error.message)
      setSubmitting(false)
    } else {
      if (result.paymentIntent.status === "succeeded") {
        window.onbeforeunload = () => null
        removeLocalStorage(CART)
        navigate("/checkout/payment-success/")
      }
    }
  }
  function handleRefresh() {
    if (firebase && formData.clientUpdatedCartItems) {
      setUpdating(true)
      const stagedOrderInput: StagedOrderInput = {
        cartItems: formData.cartItems.map(item => {
          return {
            name: item.name,
            price: item.price,
            priceOption: item.priceOption,
            productId: item.productId,
            quantity: item.quantity,
            styleOption: item.styleOption,
            personalization: item.personalization,
            stainColor: item.stainColor,
            vinylColor: item.vinylColor,
            imagePath: item.imagePath,
          }
        }),
        shipping: formData.shipping,
        billing: formData.billing,
        invoiceId: formData.invoiceId,
        coupon: formData.coupon,
        deliveryMethod: formData.deliveryMethod,
      }
      const createStagedOrder = firebase
        .functions()
        .httpsCallable("createStagedOrder")
      createStagedOrder(stagedOrderInput)
        .then(({ data }) => {
          const cartItems = processServerCheckoutItems(data.serverCheckoutItems)
          setFormData(currentVals => ({
            ...currentVals,
            stagedOrder: data,
            cartItems,
            clientUpdatedCartItems: false,
            coupon: data.coupon,
          }))
          if (!formData.invoiceId) {
            setLocalStorage(CART, cartItems)
          }
          setUpdating(false)
        })
        .catch((err: Error) => {
          window.alert(
            `An error occurred while processing your order - please refresh the page and try again.`
          )
          setUpdating(false)
        })
    }
  }
  React.useEffect(() => {
    handleRefresh()
  }, [firebase, formData.cartItems, formData.coupon, formData.deliveryMethod])
  return (
    <>
      {updating && (
        <FullPageLoading text="Please wait while we update your order details..." />
      )}
      <form onSubmit={handleSubmit}>
        {formData.stagedOrder &&
          formData.stagedOrder.itemExceptions &&
          formData.stagedOrder.itemExceptions.length > 0 && (
            <div className="error-msg margin-bottom-2">
              <p>The following exceptions have occurred with your order:</p>
              <ul className="error-list">
                {formData.stagedOrder.itemExceptions.map((item, index) => {
                  return <li key={`list-item-${index}`}>{item}</li>
                })}
              </ul>
            </div>
          )}
        {formData.cartItems.map((item, index) => {
          function setCartItem(cartItemData: CartItem) {
            updateCartItem(cartItemData, index)
          }
          const handleRemove =
            formData.cartItems.length === 1 ? null : () => removeCartItem(index)
          return (
            <CartItemContainer
              key={`cart-item-${index}`}
              cartItem={item}
              setCartItem={setCartItem}
              handleRemove={handleRemove}
            />
          )
        })}
        <div className="margin-bottom-2">
          <CheckoutSummaryTable
            cartItems={formData.cartItems}
            coupon={formData.coupon}
            shippingTotal={
              formData.deliveryMethod === "pickup"
                ? 0
                : formData.stagedOrder
                ? formData.stagedOrder.shippingTotal
                : undefined
            }
          />
        </div>
        <div className="checkout-actions">
          {formError && (
            <div className="margin-bottom-1">
              <span className="error-msg">{formError}</span>
            </div>
          )}
          <div className="checkout-actions-grid flex-center">
            <div />
            <div className="pay-btn-container">
              <button
                className={`btn full-width primary-btn rounded-btn${
                  submitting ? " disabled" : ""
                }`}
                type="submit"
                disabled={submitting}
              >
                PLACE ORDER
                {formData.stagedOrder &&
                  ` - PAY $${convertCentsToCurrency(
                    formData.stagedOrder.orderTotal
                  )}`}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default CheckoutFormReview
