import React from "react"
import AddressFields from "./AddressFields"
import { CheckoutFormData } from "../types"

function formatPhone(phone: string) {
  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`
}

const FinishedStepShipping: React.FC<{ formData: CheckoutFormData }> = ({
  formData,
}) => {
  return (
    <div className="finished-step">
      {formData.deliveryMethod === "delivery" && (
        <div className="margin-bottom-1">
          <AddressFields {...formData.shipping} />
        </div>
      )}
      <div className="checkout-small">
        {formData.deliveryMethod === "pickup" && (
          <>
            <p>
              <strong>Delivery method: </strong> Pick-up
            </p>
            <p>
              <strong>Name:</strong> {formData.shipping.name}
            </p>
          </>
        )}
        <p>
          <strong>Email:</strong> {formData.shipping.email}
        </p>
        <p>
          <strong>Phone:</strong> {formatPhone(formData.shipping.phone)}
        </p>
      </div>
    </div>
  )
}

export default FinishedStepShipping
