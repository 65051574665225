import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"

const FullPageLoading: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className="full-page-loading">
      <div className="padding-1">
        <CircularProgress size={50} color="secondary" />
      </div>
      <div className="text-center">
        <span>{text}</span>
      </div>
    </div>
  )
}

export default FullPageLoading
