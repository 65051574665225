import React from "react"
import { convertCentsToCurrency } from "../utils"

const CheckoutActionsOrderSubtotal: React.FC<{ subtotal: number }> = ({
  subtotal,
}) => {
  return (
    <div className="checkout-actions-order-total">
      <p className="label">Order Subtotal</p>
      <h6 className="text-right">${convertCentsToCurrency(subtotal)}</h6>
    </div>
  )
}

export default CheckoutActionsOrderSubtotal
