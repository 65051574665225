import React from "react"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"

const DeliveryMethodRadioGroup: React.FC<{
  value: "delivery" | "pickup"
  setValue: (value: "delivery" | "pickup") => void
}> = ({ value, setValue }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as "delivery" | "pickup")
  }
  return (
    <FormControl component="fieldset">
      <RadioGroup
        row
        aria-label="deliveryMethod"
        name="deliveryMethod"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value="delivery"
          control={<Radio color="primary" />}
          label="Delivery"
        />
        <FormControlLabel
          value="pickup"
          control={<Radio color="primary" />}
          label="Pick-up"
        />
      </RadioGroup>
    </FormControl>
  )
}

export default DeliveryMethodRadioGroup
