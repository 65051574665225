import React from "react"
import { FormComponentProps } from "./CheckoutForm"
import Icon from "./Icon"
import { CheckoutFormData } from "../types"

interface CheckoutFormItemProps extends FormComponentProps {
  FinishedStepComponent?: React.FC<{ formData: CheckoutFormData }>
  FormComponent: React.FC<FormComponentProps>
}
const CheckoutFormItem: React.FC<CheckoutFormItemProps> = ({
  FormComponent,
  FinishedStepComponent,
  ...otherProps
}) => {
  const {
    currentStep,
    index,
    label,
    setCurrentStep,
    completedSteps,
    updateSteps,
  } = otherProps
  const active = currentStep === index
  const stepCompleted = completedSteps[index]
  function handleEditClick() {
    updateSteps(false, index)
    setCurrentStep(index)
  }
  return (
    <div
      className={`checkout-form-item${active ? " active" : ""}${
        index === completedSteps.length - 1 ? " no-border" : ""
      }`}
    >
      <div className="checkout-form-item-header">
        <div className="checkout-form-item-step">
          {stepCompleted ? (
            <div className={`checkout-step flex-justify-center complete`}>
              <Icon iconName="check" width={16} />
            </div>
          ) : (
            <div className={`checkout-step flex-justify-center`}>
              <span>{index + 1}</span>
            </div>
          )}
        </div>
        <div className="checkout-form-item-label">
          <h6>{label}</h6>
        </div>
        <div className="checkout-form-item-edit">
          {stepCompleted && !active && (
            <button className="btn checkout-edit-btn" onClick={handleEditClick}>
              Edit
            </button>
          )}
        </div>
      </div>
      {active ? (
        <div className="margin-top-2">
          <FormComponent {...otherProps} />
        </div>
      ) : stepCompleted && FinishedStepComponent ? (
        <FinishedStepComponent formData={otherProps.formData} />
      ) : null}
    </div>
  )
}

export default CheckoutFormItem
