import React from "react"
import { Router } from "@reach/router"
import CheckoutPage from "../components/CheckoutPage"
import PrivateRoute from "../components/PrivateRoute"
import PaymentSuccessPage from "../components/PaymentSuccessPage"

export default () => {
  return (
    <Router basepath="/checkout">
      <>
        <PrivateRoute component={PaymentSuccessPage} path="/payment-success/" />
        <PrivateRoute component={CheckoutPage} path="/" />
      </>
    </Router>
  )
}
