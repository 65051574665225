import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"
import StripeCardInput from "./StripeCardInput"
import { Formik, Form } from "formik"
import { FormComponentProps, REVIEW_FORM } from "./CheckoutForm"
import CardDisplay from "./CardDisplay"
import { CheckoutFormData } from "../types"
import CheckoutActionsOrderSubtotal from "./CheckoutActionsOrderSubtotal"

const CheckoutFormBilling: React.FC<FormComponentProps> = ({
  formData,
  setFormData,
  setCurrentStep,
  updateSteps,
  index,
  subtotal,
}) => {
  function handleRemoveCard() {
    setFormData((data: CheckoutFormData) => ({ ...data, paymentMethod: null }))
  }
  const stripe = useStripe()
  const elements = useElements()
  const [cardError, setCardError] = React.useState(
    "Your card number is incomplete."
  )
  return (
    <Formik
      initialValues={{ card: "" }}
      onSubmit={async () => {
        try {
          let paymentMethod = formData.paymentMethod
          if (!paymentMethod) {
            const paymentMethodData = await stripe.createPaymentMethod({
              type: "card",
              card: elements.getElement(CardElement),
            })
            if (paymentMethodData.error) {
              throw new Error(paymentMethodData.error.message)
            }
            paymentMethod = {
              id: paymentMethodData.paymentMethod.id,
              brand: paymentMethodData.paymentMethod.card.brand,
              last4: paymentMethodData.paymentMethod.card.last4,
              expMonth: paymentMethodData.paymentMethod.card.exp_month,
              expYear: paymentMethodData.paymentMethod.card.exp_year,
            }
          }
          setFormData((currentVals: CheckoutFormData) => ({
            ...currentVals,
            paymentMethod,
          }))
          updateSteps(true, index)
          setCurrentStep(2)
          scrollTo(`#${REVIEW_FORM}`)
        } catch (err) {
          setCardError(err.message)
        }
      }}
    >
      {({ setFieldTouched, touched, isSubmitting, submitCount }) => {
        return (
          <Form>
            {formData.paymentMethod ? (
              <CardDisplay
                brand={formData.paymentMethod.brand}
                last4={formData.paymentMethod.last4}
                expMonth={formData.paymentMethod.expMonth}
                expYear={formData.paymentMethod.expYear}
                handleRemoveCard={handleRemoveCard}
              />
            ) : (
              <StripeCardInput
                setCardError={setCardError}
                cardError={cardError}
                hasError={Boolean(
                  (touched.card || submitCount > 0) && cardError
                )}
                handleBlur={() => setFieldTouched("card", true)}
              />
            )}
            <div className="checkout-actions">
              <div className="checkout-actions-grid flex-center">
                <div>
                  <CheckoutActionsOrderSubtotal subtotal={subtotal} />
                </div>
                <div>
                  <button
                    className={`btn primary-btn checkout-btn rounded-btn${
                      isSubmitting ? " disabled" : ""
                    }`}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CheckoutFormBilling
