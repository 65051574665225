import React from "react"
import GatsbyImage from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import CheckoutFormShipping from "./CheckoutFormShipping"
import CheckoutFormItem from "./CheckoutFormItem"
import CheckoutFormBilling from "./CheckoutFormBilling"
import CheckoutFormReview from "./CheckoutFormReview"
import {
  getLocalStorage,
  convertCentsToCurrency,
  getCheckoutSummaryPrices,
} from "../utils"
import { CART } from "../constants"
import { CartItem, CheckoutFormData } from "../types"
import { STRIPE_PUBLISHABLE_KEY } from "../config"
import useFirebase from "../useFirebase"
import CheckoutSummaryTable from "./CheckoutSummaryTable"
import FinishedStepShipping from "./FinishedStepShipping"
import FinishedStepBilling from "./FinishedStepBilling"
import FullPageLoading from "./FullPageLoading"
import CheckoutCouponForm from "./CheckoutCouponForm"

export interface FormComponentProps {
  formData: CheckoutFormData
  setFormData: React.Dispatch<React.SetStateAction<CheckoutFormData>>
  currentStep: number
  completedSteps: boolean[]
  updateSteps: (val: boolean, index: number) => void
  index: number
  setCurrentStep: (step: number) => void
  label: string
  subtotal: number
}
interface CheckoutStep {
  label: string
  FormComponent: React.FC<FormComponentProps>
  FinishedStepComponent: React.FC<{ formData: CheckoutFormData }> | null
  id: string
}
export const BILLING_FORM = "billing-form"
export const REVIEW_FORM = "review-form"
export const checkoutSteps: CheckoutStep[] = [
  {
    label: "Shipping Information",
    FormComponent: CheckoutFormShipping,
    FinishedStepComponent: FinishedStepShipping,
    id: "shipping-form",
  },
  {
    label: "Payment Information",
    FormComponent: CheckoutFormBilling,
    FinishedStepComponent: FinishedStepBilling,
    id: BILLING_FORM,
  },
  {
    label: "Review Order",
    FormComponent: CheckoutFormReview,
    FinishedStepComponent: null,
    id: REVIEW_FORM,
  },
]
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

const CheckoutForm: React.FC<{ invoiceId: string | null }> = ({
  invoiceId,
}) => {
  const [invoiceError, setInvoiceError] = React.useState("")
  const [loadingInvoice, setLoadingInvoice] = React.useState(false)
  const [currentStep, setCurrentStep] = React.useState(0)
  const [completedSteps, setCompletedSteps] = React.useState<boolean[]>(
    checkoutSteps.map(() => false)
  )
  const [formData, setFormData] = React.useState<CheckoutFormData>({
    cartItems: [],
    clientUpdatedCartItems: true,
    deliveryMethod: "delivery",
    email: "",
    name: "",
    phone: "",
    shipping: null,
    billing: null,
    paymentMethod: null,
    stagedOrder: null,
    coupon: null,
  })
  const { subtotal } = getCheckoutSummaryPrices(
    formData.cartItems,
    formData.coupon,
    formData.stagedOrder?.shippingTotal
  )
  function updateSteps(val: boolean, index: number) {
    setCompletedSteps(steps => {
      const newSteps = [...steps]
      newSteps[index] = val
      return newSteps
    })
  }
  const firebase = useFirebase()
  const placeholderImageData = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "product_placeholder.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  React.useEffect(() => {
    if (firebase && invoiceId) {
      setLoadingInvoice(true)
      const getInvoice = firebase.functions().httpsCallable("getInvoice")
      getInvoice({ invoiceId })
        .then(({ data }) => {
          const cartItem: CartItem = {
            name: "Custom Product",
            multiplePriceOptions: false,
            productId: data.productId,
            personalization: data.description,
            price: data.price,
            priceOption: data.priceOption,
            productImage: placeholderImageData.placeholderImage,
            quantity: data.quantity,
            styleOption: "",
            styleOptionLabel: "",
            disableQuantityUpdate: true,
          }
          setFormData({
            ...formData,
            cartItems: [cartItem],
            invoiceId: data.invoiceId,
          })
          setLoadingInvoice(false)
        })
        .catch((err: Error) => {
          setInvoiceError(err.message)
          setLoadingInvoice(false)
        })
    } else {
      const localCart = getLocalStorage(CART)
      if (localCart) {
        setFormData({ ...formData, cartItems: getLocalStorage(CART) || [] })
      }
    }
  }, [invoiceId, firebase])
  return (
    <>
      {loadingInvoice && (
        <FullPageLoading text="Please wait while we get your order details..." />
      )}
      <div className="layout-container">
        {invoiceError ? (
          <div>
            <p className="error-msg text-center">Error: {invoiceError}</p>
          </div>
        ) : (
          <div className="checkout-grid">
            <Elements stripe={stripePromise}>
              <div className="checkout-grid-item-form">
                <div>
                  <CheckoutCouponForm
                    formData={formData}
                    setFormData={setFormData}
                  />
                </div>
                {checkoutSteps.map(
                  (
                    { label, FormComponent, FinishedStepComponent, id },
                    index
                  ) => {
                    return (
                      <div key={id} id={id}>
                        <CheckoutFormItem
                          label={label}
                          FormComponent={FormComponent}
                          FinishedStepComponent={FinishedStepComponent}
                          formData={formData}
                          setFormData={setFormData}
                          currentStep={currentStep}
                          setCurrentStep={setCurrentStep}
                          index={index}
                          completedSteps={completedSteps}
                          updateSteps={updateSteps}
                          subtotal={subtotal}
                        />
                      </div>
                    )
                  }
                )}
              </div>
            </Elements>
            <div className="checkout-grid-item-summary">
              <div className="checkout-paper padding-2">
                <h6 className="margin-bottom-2">Order Summary</h6>
                {formData.cartItems.map((item, index) => {
                  return (
                    <div
                      key={`checkout-subitem-${index}`}
                      className="checkout-paper-item"
                    >
                      <div className="image">
                        <GatsbyImage
                          fluid={item.productImage.childImageSharp.fluid}
                        />
                      </div>
                      <div className="content">
                        <div>
                          <strong>{item.name}</strong>
                        </div>
                        <div>Quantity: {item.quantity}</div>
                      </div>
                      <div className="price">
                        <div>${convertCentsToCurrency(item.price)}</div>
                      </div>
                    </div>
                  )
                })}
                <CheckoutSummaryTable
                  cartItems={formData.cartItems}
                  coupon={formData.coupon}
                  shippingTotal={
                    formData.deliveryMethod === "pickup"
                      ? 0
                      : formData.stagedOrder
                      ? formData.stagedOrder.shippingTotal
                      : undefined
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export default CheckoutForm
