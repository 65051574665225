import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Formik, Form } from "formik"
import CheckoutField, { CheckoutFieldProps } from "./CheckoutField"
import PhoneNumberInput from "./PhoneNumberInput"
import { BILLING_FORM, FormComponentProps, REVIEW_FORM } from "./CheckoutForm"
import { CheckoutFormData } from "../types"
import CheckoutActionsOrderSubtotal from "./CheckoutActionsOrderSubtotal"
import DeliveryMethodRadioGroup from "./DeliveryMethodRadioGroup"

const contactFields: CheckoutFieldProps[] = [
  {
    label: "Email",
    grid: 2,
    name: "email",
    type: "email",
    helperText: "* For order confirmation",
  },
]

export const shippingFields: CheckoutFieldProps[] = [
  {
    label: "Street address",
    name: "addressLine1",
    grid: 1,
  },
  {
    label: "Apt / Suite / Other (optional)",
    name: "addressLine2",
    grid: 1,
  },
  {
    label: "ZIP Code",
    name: "zip",
    type: "tel",
    grid: 2,
  },
  {
    label: "City",
    name: "city",
    grid: 2,
  },
  {
    label: "State",
    name: "state",
    grid: 1,
    options: [
      "",
      "Alabama",
      "Alaska",
      "American Samoa",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Federated States of Micronesia",
      "Florida",
      "Georgia",
      "Guam",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Marshall Islands",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Northern Mariana Islands",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Palau",
      "Pennsylvania",
      "Puerto Rico",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virgin Island",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
  },
]

export const validateShippingFields = (values: any, errors: any) => {
  ;["addressLine1", "city", "state"].forEach(item => {
    if (!values[item]) {
      errors[item] = "This is a required field"
    }
  })
  if (/\b\d{5}\b/.test(values.zip) === false) {
    errors.zip = "Please enter a valid 5-digit ZIP code"
  }
}

const CheckoutFormShipping: React.FC<FormComponentProps> = ({
  formData,
  setFormData,
  setCurrentStep,
  updateSteps,
  completedSteps,
  index,
  subtotal,
}) => {
  const setDeliveryMethod = (deliveryMethod: "delivery" | "pickup") => {
    setFormData(currentVals => {
      return {
        ...currentVals,
        clientUpdatedCartItems: true,
        deliveryMethod,
      }
    })
  }
  const initialValues =
    formData.shipping ||
    shippingFields.reduce(
      (a, b) => {
        a[b.name] = ""
        return a
      },
      { email: "", phone: "", name: "" } as CheckoutFormData["shipping"]
    )
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        setFormData((currentVals: CheckoutFormData) => ({
          ...currentVals,
          shipping: values,
        }))
        updateSteps(true, index)
        setCurrentStep(completedSteps[1] ? 2 : 1)
        scrollTo(completedSteps[1] ? `#${REVIEW_FORM}` : `#${BILLING_FORM}`)
      }}
      validate={values => {
        const errors: { [key: string]: string } = {}
        if (formData.deliveryMethod === "delivery") {
          validateShippingFields(values, errors)
        }
        if (
          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            values.email
          ) === false
        ) {
          errors.email = "Please enter a valid email address"
        }
        if (values.phone.length !== 10) {
          errors.phone = "Please enter a valid 10-digit phone number"
        }
        if (!values.name) {
          errors.name = "Please enter your full name"
        }
        return errors
      }}
    >
      {({ errors, submitCount }) => {
        return (
          <Form>
            <div className="margin-bottom-1">
              <DeliveryMethodRadioGroup
                value={formData.deliveryMethod}
                setValue={setDeliveryMethod}
              />
            </div>
            <div className="grid-container add-width-1">
              <CheckoutField label="Full name" name="name" grid={1} />
              {formData.deliveryMethod === "delivery" &&
                shippingFields.map((field, index) => (
                  <CheckoutField {...field} key={`field-${index}`} />
                ))}
              {contactFields.map((field, index) => (
                <CheckoutField {...field} key={`field-${index}`} />
              ))}
              <div className="grid-item-2 padding-1">
                <PhoneNumberInput />
              </div>
            </div>
            {formData.deliveryMethod === "pickup" && (
              <div>
                <p className="text-muted">
                  Your order can be picked up at the address below; we will
                  contact you when your order is ready.
                </p>
                <div className="pickup-address">
                  <p>295 Twin Oaks Dr</p>
                  <p>Wexford, PA 15090</p>
                </div>
              </div>
            )}
            <div className="checkout-actions">
              {Object.keys(errors).length > 0 && submitCount > 0 && (
                <div className="margin-bottom-1">
                  <span className="error-msg">
                    Please correct the errors above to continue
                  </span>
                </div>
              )}
              <div className="checkout-actions-grid flex-center">
                <div>
                  <CheckoutActionsOrderSubtotal subtotal={subtotal} />
                </div>
                <div>
                  <button
                    className="btn primary-btn rounded-btn checkout-btn"
                    type="submit"
                  >
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CheckoutFormShipping
