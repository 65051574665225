import React from "react"
import { CartItem, Coupon } from "../types"
import { convertCentsToCurrency, getCheckoutSummaryPrices } from "../utils"

const CheckoutSummaryTable: React.FC<{
  cartItems: CartItem[]
  coupon: Coupon
  shippingTotal?: number
}> = ({ cartItems, coupon, shippingTotal }) => {
  const { subtotal, couponDiscount, orderTotal } = getCheckoutSummaryPrices(
    cartItems,
    coupon,
    shippingTotal || 0
  )
  return (
    <div className="checkout-summary-table">
      <div className="checkout-summary-row">
        <div>Subtotal</div>
        <div>${convertCentsToCurrency(subtotal)}</div>
      </div>
      {couponDiscount && (
        <div className="checkout-summary-row coupon-row">
          <div>Promo Code {coupon.code}</div>
          <div>(${convertCentsToCurrency(couponDiscount)})</div>
        </div>
      )}
      <div className="checkout-summary-row">
        <div>Shipping</div>
        <div>
          {shippingTotal === undefined
            ? "TBD"
            : `$${convertCentsToCurrency(shippingTotal)}`}
        </div>
      </div>
      <div className="checkout-summary-row">
        <div>Order Total</div>
        <div>
          {shippingTotal === undefined
            ? "TBD"
            : `$${convertCentsToCurrency(orderTotal)}`}
        </div>
      </div>
    </div>
  )
}

export default CheckoutSummaryTable
