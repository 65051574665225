import React from "react"

interface AddressFieldsProps {
  name: string
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zip: string
}
const AddressFields: React.FC<AddressFieldsProps> = ({
  name,
  addressLine1,
  addressLine2,
  city,
  state,
  zip,
}) => {
  return (
    <div className="checkout-small">
      <p>{name}</p>
      <p>{addressLine1}</p>
      {addressLine2 && <p>{addressLine2}</p>}
      <p>
        {city}, {state}, {zip}
      </p>
    </div>
  )
}

export default AddressFields
